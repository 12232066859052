import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { convertNumbersToK, numberWithCommas } from 'utils';

import { ChatterMarketIcon, PriceMarketIcon, SmallBarIcon, VolumeMarketIcon } from '../../components/SvgComponents';
import { FiltersDateRangeType } from '../../types';
import styles from './SnapshotGraphs/styles.module.scss';
import {
  ChartSeriesName,
  IChatMarketData,
  ILinearChartResponse,
  ILinearChartSeriesItem,
  ISentimentsSnapshotResponse
} from './types';

export const getMaxAndMinFromSeries = (series: ILinearChartSeriesItem[], findableName: string) => {
  const findSeries = series.find(({ name }) => name === findableName) || { data: [] };

  const validData = findSeries.data.filter((val) => val !== null);

  const max = Math.max(...validData);
  const min = Math.min(...validData);

  return {
    max: max + max * 0.001,
    min: min - min * 0.001
  };
};

export const renderChatMarketTooltip = (data: any, styles: any, selectedTimeZone: string = 'local') => {
  const { series, seriesIndex, dataPointIndex, w, interval } = data;
  const config = w?.config;
  const dataTooltip = w?.globals.lastXAxis.labels.categories[dataPointIndex];

  const parsedDate = DateTime.fromJSDate(new Date(dataTooltip)).setZone(selectedTimeZone);
  const formattedDate = parsedDate.toFormat('LLL dd, yyyy');

  return renderToString(
    <div className={styles.tooltip}>
      <div className={styles.top}>
        {interval === '1d' && <div className={styles.tooltipTitle}>Click on the point to show a detailed report</div>}
        <div className={styles.date}>{formattedDate}</div>
      </div>
      {series.map((item: any, index: number) => {
        const name = config.series[index].name;
        const value = series[index][dataPointIndex];
        const Icon =
          name.toLowerCase() === ChartSeriesName.volume
            ? VolumeMarketIcon
            : name.toLowerCase() === ChartSeriesName.price
            ? PriceMarketIcon
            : ChatterMarketIcon;
        return (
          <div className={styles.row} key={index}>
            <div className={styles.toolTipIconWrapper}>
              <Icon />
            </div>
            <div className={styles.name}>{name}:</div>
            <div className={styles.value}>
              {name.toLowerCase() === ChartSeriesName.price && !!value && '$'}
              {name.toLowerCase() === ChartSeriesName.price
                ? value
                : value?.toLocaleString('en-US', {
                    style: 'decimal',
                    useGrouping: true,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }) || 'No data'}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const renderTooltip = (data: any, styles: any, selectedTimeZone: string = 'local') => {
  const { series, seriesIndex, dataPointIndex, w } = data;
  const config = w?.config;
  const dataTooltip = w?.globals.lastXAxis.labels.categories[dataPointIndex];

  const parsedDate = DateTime.fromJSDate(new Date(dataTooltip)).setZone(selectedTimeZone);
  const formattedDate = parsedDate.toFormat('LLL dd, yyyy');
  const formattedTime = parsedDate.toFormat('hh:mm:ss a');
  return renderToString(
    <div className={styles.tooltip}>
      <div className={styles.top}>
        <div className={styles.date}>{formattedDate}</div>
        <div className={styles.time}>{formattedTime}</div>
      </div>
      {series.map((item: any, index: number) => {
        const name = config.series[index].name;
        const value = series[index][dataPointIndex];
        return (
          <div className={styles.row} key={index}>
            {name.toLowerCase() === ChartSeriesName.volume ? (
              <SmallBarIcon />
            ) : (
              <div className={styles.icon} style={{ backgroundColor: config.colors[index] }}></div>
            )}

            <div className={styles.name}>{name}:</div>
            <div className={styles.value}>
              {name.toLowerCase() === ChartSeriesName.price && !!value && '$'}
              {name.toLowerCase() === ChartSeriesName.price
                ? value
                : value?.toLocaleString('en-US', {
                    style: 'decimal',
                    useGrouping: true,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }) || 'No data'}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const getSerieKeyFromName = (name: string) => {
  const splitedName = name.split(' ');
  return splitedName[0].toLowerCase();
};

export const renderCompetitiveTooltip = (data: any, activeSeries: string, styles: any, selectedTimeZone: string) => {
  const { seriesIndex, dataPointIndex, w } = data;
  const config = w?.config;
  const dateTooltip = w?.globals.lastXAxis.labels.categories[dataPointIndex];
  let series: any[] = config.series;

  if (activeSeries) {
    series = series.filter(({ name }) => {
      const [seriesName] = name.split(' ');
      return seriesName.toLowerCase() === activeSeries;
    });
  }

  const companies = ['', ...new Set(series.map(({ group }) => group))];

  const parsedDate = DateTime.fromJSDate(new Date(dateTooltip)).setZone(selectedTimeZone);
  const formattedDate = parsedDate.toFormat('LLL dd, yyyy');
  const formattedTime = parsedDate.toFormat('hh:mm:ss a');

  const labels = [...new Set(series.map(({ name }) => getSerieKeyFromName(name)))];

  if (!activeSeries) {
    return renderToString(
      <div className={styles.tooltip}>
        <div className={styles.top}>
          <div className={styles.date}>{formattedDate}</div>
          <div className={styles.time}>{formattedTime}</div>
        </div>
        {companies.map((company: string, index: number) => {
          if (!company) {
            return (
              <div className={styles.row} key={index}>
                <div className={styles.nameWrapper}></div>
                {labels.map((name: string) => {
                  return (
                    <div key={name} className={classNames(styles.colWrapper, styles.title)}>
                      {name}
                    </div>
                  );
                })}
              </div>
            );
          }

          const dataItems = series.filter(({ group }) => group === company);
          const [firstItem] = dataItems;

          return (
            <div className={styles.row} key={index}>
              <div className={styles.nameWrapper}>
                <div className={styles.icon} style={{ backgroundColor: firstItem.color }}></div>
                <div className={styles.name}>{company}</div>
              </div>
              {dataItems.map((item) => {
                const { name, data } = item;
                const value = data[dataPointIndex];
                const seriesName = getSerieKeyFromName(name);

                return (
                  <div key={name} className={styles.colWrapper}>
                    {seriesName === ChartSeriesName.price && '$'}
                    {value ? convertNumbersToK(value) : '—'}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  return renderToString(
    <div className={styles.tooltip}>
      <div className={styles.top}>
        <div className={styles.date}>{formattedDate}</div>
        <div className={styles.time}>{formattedTime}</div>
      </div>
      {series.map((item: any, index: number) => {
        const name = item.group;
        let value = item.data[dataPointIndex];
        value = value ? convertNumbersToK(item.data[dataPointIndex]) : '—';
        return (
          <div className={classNames(styles.row, styles.bigIndent)} key={index}>
            <div className={styles.nameWrapper}>
              <div className={styles.icon} style={{ backgroundColor: item.color }}></div>
              <div className={styles.name}>{name}</div>
            </div>
            <div className={styles.value}>{value}</div>
          </div>
        );
      })}
    </div>
  );
};

export const getFormattedSeries = (array: any = [], additionalOptsFn?: (key: string) => Record<string, any>) => {
  return array?.map((item: any) => {
    const additionalOptions = additionalOptsFn ? additionalOptsFn(item.key) : {};
    return {
      name: item.key ? `${item.name} ${item.key}` : item.name,
      type: item.type,
      data: item.data,
      ...additionalOptions,
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0
      }
    };
  });
};

export const preloaderLinearChartData: ILinearChartResponse | IChatMarketData = {
  series: [
    {
      name: 'Positive',
      type: 'line',
      data: [11, 57, 36, 67, 58, 133, 62, 75, 55, 44]
    },
    {
      name: 'Negative',
      type: 'line',
      data: [30, 27, 73, 39, 93, 62, 102, 73, 48, 31]
    },
    {
      name: 'Price',
      type: 'line',
      data: [10.98, 10.95, 10.97, 11.01, 11.11, 11.341, 11.42, 11.83, 12.49, 12.93]
    }
  ],
  dates: [
    '08/30/2023 11:00Z',
    '08/30/2023 11:30Z',
    '08/30/2023 12:00Z',
    '08/30/2023 12:30Z',
    '08/30/2023 13:00Z',
    '08/30/2023 13:30Z',
    '08/30/2023 14:00Z',
    '08/30/2023 14:30Z',
    '08/30/2023 15:00Z',
    '08/30/2023 15:30Z'
  ],
  prLinks: []
};

export const preloaderSnapshotData: ISentimentsSnapshotResponse = {
  sentiments: [
    {
      label: 'Positive',
      count: 1144,
      percentage: '31.2%',
      sentimentValue: 2
    },
    {
      label: 'Neutral',
      count: 1426,
      percentage: '38.9%',
      sentimentValue: 1
    },
    {
      label: 'Negative',
      count: 1094,
      percentage: '29.9%',
      sentimentValue: 3
    }
  ],
  total: 100
};

export const renderSpanShotLegend = (seriesName: any, option: any, colorScheme: any, initialData: any[]) => {
  const { seriesIndex, w } = option;
  const global = w?.globals;
  const percentage = initialData[seriesIndex].percentage;
  return renderToString(
    <div className={styles.block}>
      <div className={styles.marker}>
        <div className={styles.dot} style={{ backgroundColor: colorScheme[seriesIndex] }}></div>
        {seriesName}
      </div>
      <div className={styles.labels}>
        <div>{numberWithCommas(global.initialSeries[seriesIndex])}</div>
        <div>{percentage}</div>
      </div>
    </div>
  );
};

export const getXaxisLabels = (value: any, filterType: FiltersDateRangeType, selectedTimeZone: string = 'local') => {
  const timeFormatString = filterType < 3 ? 'HH:mm' : 'dd MMM';
  const parsedDate = DateTime.fromJSDate(new Date(value));
  const formattedTime = parsedDate.setZone(selectedTimeZone).toFormat(timeFormatString);
  return formattedTime;
};

export const getXaxisTooltipLabel = (value: any, data: any, selectedTimeZone: string = 'local') => {
  const { series, seriesIndex, dataPointIndex, w } = data;
  const date = w?.globals?.lastXAxis.labels.categories[dataPointIndex];
  const parsedDate = DateTime.fromJSDate(new Date(date));
  const formattedTime = parsedDate.setZone(selectedTimeZone).toFormat('dd MMM yy HH:mm:ss');
  return formattedTime;
};
