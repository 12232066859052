import GaugeBar from './GaugeBar';

interface ISentimentGaugeProps {
  type: 'bar' | 'chart';
  loading: boolean;
  percentage: number;
}

const SentimentGauge = (props: ISentimentGaugeProps) => {
  return <GaugeBar {...props} />;
};

export default SentimentGauge;
