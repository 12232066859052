import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Avatar } from '@mui/material';
import InputField, { InputFieldType } from 'components/InputField';
import useDelayedWatch from 'hooks/useDelayedWatch';
import { DateTime } from 'luxon';
import ContentBlock from 'pages/Admin/ContentBlock';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CrudActions, CrudTable, Title, TitleType } from '../../../../components';
import Link, { LinkType } from '../../../../components/Link';
import { API_ROUTES, APP_ROUTS } from '../../../../const';
import { theme } from '../../../../theme';
import { IStock, ITableColumns } from '../../../../types';
import DashboardLayout from '../../DashboardLayout';
import styles from './styles.module.scss';

const rows: ITableColumns<IStock>[] = [
  {
    title: '',
    dataIndex: 'image',
    key: 'image',
    width: 30,
    render: (item, value) => {
      return <Avatar src={value} sx={{ width: 24, height: 24 }} alt={item.name} />;
    }
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sortable: true
  },
  {
    title: 'Date created',
    dataIndex: 'dateCreated',
    key: 'dateCreated',
    sortable: true,
    render: (item, value) => {
      return <span>{DateTime.fromJSDate(new Date(value)).toFormat('LLL dd, yyyy')}</span>;
    }
  },
  {
    title: 'Symbol',
    dataIndex: 'symbol',
    key: 'symbol',
    sortable: true
  },
  {
    title: 'Is active',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (item, value) => {
      return value ? (
        <CheckIcon
          style={{
            color: theme.palette.success.light
          }}
        />
      ) : (
        <ClearIcon style={{ color: theme.palette.error.main }} />
      );
    },
    align: 'center'
  },
  {
    title: '',
    dataIndex: '',
    key: '_id',
    render: (item, value, refresh = () => {}) => {
      return (
        <CrudActions
          toEdit={`${APP_ROUTS.STOCKS_EDIT}/${item.symbol}`}
          onRemoveUrl={`${API_ROUTES.STOCKS}/${value}`}
          dialogTitle={
            <div>
              <Title type={TitleType.h3}>
                <>Confirm delete Stock.</>
              </Title>
              <Title type={TitleType.h5}>
                <> Stock Name: {item.name}</>
              </Title>
            </div>
          }
          refresh={refresh}
        />
      );
    }
  }
];

type FilterValues = {
  symbol: string;
  name: string;
};

export const StocksList = () => {
  const { t } = useTranslation();
  const { register, control, watch } = useForm<FilterValues>({
    defaultValues: { symbol: '', name: '' }
  });

  const delayedValues = useDelayedWatch(watch, ['symbol', 'name'], 1000);
  const queryOptions = useMemo(() => {
    const options: { symbol?: string; name?: string } = {};

    (Object.keys(delayedValues) as Array<'symbol' | 'name'>).forEach((key) => {
      if (delayedValues[key]) {
        options[key] = delayedValues[key];
      }
    });

    return options;
  }, [delayedValues]);

  return (
    <DashboardLayout
      title={t('pages.admin.stocks')}
      createLink={<Link to={APP_ROUTS.STOCKS_CREATE} label={'create'} linkType={LinkType.simple} />}
    >
      <>
        <ContentBlock>
          <div className={styles.filters}>
            <InputField
              control={control}
              name="symbol"
              defaultValue=""
              errors={undefined}
              placeholder="Enter a stock symbol"
              register={register}
              type={InputFieldType.text}
              validate={{}}
            />
            <InputField
              control={control}
              name="name"
              defaultValue=""
              errors={undefined}
              placeholder="Enter a stock name"
              register={register}
              type={InputFieldType.text}
              validate={{}}
            />
          </div>
        </ContentBlock>
        <CrudTable<IStock>
          columns={rows}
          requestUrl={API_ROUTES.STOCKS}
          queryOptions={queryOptions}
          defaultSorting={{ name: 'name', direction: 1 }}
        />
      </>
    </DashboardLayout>
  );
};
