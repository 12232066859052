import { Skeleton, Typography } from '@mui/material';
import classNames from 'classnames';
import useCompetitiveStocks from 'hooks/useCompetitiveStocks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPercentage } from 'utils';

import { IStockSentiment } from '../index';
import styles from './styles.module.scss';

interface ISentimentListProps {
  data?: IStockSentiment[];
  loading: boolean;
}

const SentimentsList: React.FC<ISentimentListProps> = ({ data, loading }) => {
  const { competitiveStocks, mainStock } = useCompetitiveStocks();
  const { t } = useTranslation();

  if (!data || loading || !competitiveStocks) {
    return (
      <div className={classNames(styles.listWrapper, styles.noMargin)}>
        {Array.from(Array(4)).map((item, index) => {
          return (
            <div className={styles.skeletonWrapper} key={index}>
              <Skeleton variant="rounded" height={46} width={46} />
              <Skeleton variant="text" height={46} width="100%" />
            </div>
          );
        })}
      </div>
    );
  }

  const sortedData = [...data].sort((x, y) => {
    if (x.stock === mainStock.symbol) return -1;
    if (y.stock === mainStock.symbol) return 1;

    return y.total - x.total;
  });

  return (
    <div className={styles.listWrapper}>
      {sortedData.map(({ stock, sentiments, total }) => {
        const stockData = [...competitiveStocks, mainStock].find(({ symbol }) => symbol === stock);

        if (!stockData) {
          return null;
        }

        const isMainStock = stock === mainStock.symbol;

        const { image } = stockData;
        const { positive, negative, neutral } = sentiments;

        const negativePercentage = getPercentage(negative, total);
        const positivePercentage = getPercentage(positive, total);
        const neutralPercentage = getPercentage(neutral, total);

        return (
          <div className={classNames(styles.wrapper, { [styles.main]: isMainStock })} key={stock}>
            <div className={styles.imageWrapper}>
              <img width={24} height={24} src={image} alt="" />
            </div>
            <div className={styles.barWrapper}>
              {isMainStock && (
                <Typography className={styles.mainStockText} variant="body2">
                  {t('pages.competitiveAnalysis.yourStock')}
                </Typography>
              )}
              <div className={styles.bar}>
                <div className={classNames(styles.barItem, styles.negative)} style={{ width: negativePercentage }}>
                  {negativePercentage}
                </div>
                <div className={classNames(styles.barItem, styles.positive)} style={{ width: positivePercentage }}>
                  {positivePercentage}
                </div>
                <div className={classNames(styles.barItem, styles.neutral)} style={{ width: neutralPercentage }}>
                  {neutralPercentage}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SentimentsList;
