import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import ApexCharts from 'apexcharts';
import classNames from 'classnames';
import { ChatterMarketIcon, PriceMarketIcon, VolumeMarketIcon } from 'components/SvgComponents';
import React, { useState } from 'react';

import styles from './styles.module.scss';

interface ILegendsProps {
  activeLegend: string;
  onChangeLegend: (legend: string) => void;
}

const Legends: React.FC<ILegendsProps> = ({ activeLegend, onChangeLegend }) => {
  const onLegendClick = (seriesName: string) => {
    return () => {
      onChangeLegend(activeLegend === seriesName ? '' : seriesName);
    };
  };

  return (
    <div className={styles.legends}>
      <div
        className={classNames(styles.legend, { [styles.active]: activeLegend === 'Price' })}
        onClick={onLegendClick('Price')}
      >
        <PriceMarketIcon />
        <span className={styles.legendText}>Price</span>
        <Tooltip title="The price at which a share of stock is trading on the market">
          <InfoOutlinedIcon fontSize="small" htmlColor="#667085" />
        </Tooltip>
      </div>
      <div
        className={classNames(styles.legend, { [styles.active]: activeLegend === 'Chatter' })}
        onClick={onLegendClick('Chatter')}
      >
        <ChatterMarketIcon />
        <span className={styles.legendText}>Chatter</span>
        <Tooltip title="The total amount of posts, comments or videos that mentioned the stock">
          <InfoOutlinedIcon fontSize="small" htmlColor="#667085" />
        </Tooltip>
      </div>
      <div
        className={classNames(styles.legend, { [styles.active]: activeLegend === 'Volume' })}
        onClick={onLegendClick('Volume')}
      >
        <VolumeMarketIcon />
        <span className={styles.legendText}>Volume</span>
        <Tooltip title="The total number of shares traded during a given period of time">
          <InfoOutlinedIcon fontSize="small" htmlColor="#667085" />
        </Tooltip>
      </div>
    </div>
  );
};

export default Legends;
