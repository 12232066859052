import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import axios, { AxiosRequestConfig } from 'axios';
import { YouTubeIcon } from 'components/SvgComponents/DataSource/YouTubeIcon';
import { BASE_API_URL, ERROR_API_RESPONSE, TOKEN_KEY } from 'const';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { Datasource, FiltersDateRangeType, ServerError, SomeRequired } from 'types';

import { RedditIcon, StockTwits, TwitterIcon, YahooFinance } from '../components/SvgComponents';
import LoggerService from '../services/LoggerService';
import ToastifyService from '../services/ToastifyService';
import { store } from '../store';
import { getUserAuthToken, logout } from '../store/slices/authSlice';

export const axiosErrorHandler = async (error: ServerError) => {
  const listeners: Record<number | string, (message: string) => any> = {
    [ERROR_API_RESPONSE.NOT_AUTHORIZED]: () => {
      localStorage.removeItem(TOKEN_KEY);
    },
    [ERROR_API_RESPONSE.BAD_REQUEST]: (message: string) => {
      LoggerService.log(message);
      ToastifyService.setToast(message, 'error');
    },
    [ERROR_API_RESPONSE.SERVER_ERROR]: (message: string) => {
      LoggerService.log(message);
      ToastifyService.setToast(message, 'error');
    },
    [ERROR_API_RESPONSE.FORBIDDEN]: () => {
      store.dispatch(logout());
    }
  };

  const errorData = error.response?.data;
  const trigger = errorData?.statusCode || error.status;

  if (Array.isArray(errorData?.error?.data)) {
    throw error;
  }
  const message =
    typeof errorData?.error?.data === 'string'
      ? errorData?.error?.data
      : errorData?.error?.message || errorData?.message || 'Something went wrong...';
  typeof listeners[trigger] === 'function'
    ? listeners[trigger](message || 'Something went wrong...')
    : LoggerService.log(message || 'Something went wrong...');
  throw error;
};

export const fetchWithConfig = async <T = Record<string, unknown>>(
  config: SomeRequired<AxiosRequestConfig, 'url'>
): Promise<T> => {
  const defaultConfig = {
    method: 'GET'
  };

  const getHeaders = async (baseHeaders: AxiosRequestConfig['headers']) => {
    const newHeaders = baseHeaders || {};
    const token = localStorage.getItem(TOKEN_KEY) || getUserAuthToken(store.getState());
    if (token) {
      newHeaders['Authorization'] = `Bearer ${token}`;
    }
    return newHeaders;
  };

  return axios
    .request({
      ...defaultConfig,
      ...config,
      headers: await getHeaders(config.headers),
      url: `${BASE_API_URL}/${config.url}`
    })
    .then((res) => res.data)
    .then((res) => (typeof res?.data !== 'undefined' ? res.data : res))
    .catch(async (res) => {
      await axiosErrorHandler(res);
    });
};

export const formatDate = (date: Date, selectedTimeZone: string = 'UTC', keepLocal: boolean = true) => {
  const dateWithTimeZone = DateTime.fromJSDate(date).setZone(selectedTimeZone, { keepLocalTime: keepLocal });
  return dateWithTimeZone.toString();
};

export const getStaticTimeRange = (
  rangeType: FiltersDateRangeType,
  selectedTimeZone: string
): { from: string; to: string } => {
  const to = formatDate(new Date(), selectedTimeZone, false);
  const formatH = (h: number) => formatDate(DateTime.now().minus({ hour: h }).toJSDate(), selectedTimeZone, false);
  const formatD = (d: number) =>
    formatDate(DateTime.now().minus({ days: d }).startOf('day').setZone(selectedTimeZone).toJSDate(), selectedTimeZone);

  let from = formatH(24);

  switch (rangeType) {
    case FiltersDateRangeType.h24:
      return {
        from: formatH(24),
        to
      };
    case FiltersDateRangeType.h48:
      return {
        from: formatH(48),
        to
      };
    case FiltersDateRangeType.h72:
      return {
        from: formatH(72),
        to
      };
    case FiltersDateRangeType.w1:
      return {
        from: formatD(6),
        to
      };
    case FiltersDateRangeType.m1:
      return {
        from: formatDate(DateTime.now().minus({ month: 1 }).startOf('day').toJSDate(), selectedTimeZone),
        to
      };
    default:
      return {
        from,
        to
      };
  }
};

export const formatDateTime = (date: Date | null): string => {
  if (!date) {
    return 'MM - DD - YY';
  }
  const luxonDateTime = DateTime.fromJSDate(date);
  return luxonDateTime.toFormat('LLL dd, yyyy');
};

export const getDateSourceIcon = (source: Datasource, props = {}) => {
  const iconsConfig = [TwitterIcon, StockTwits, YahooFinance, RedditIcon, null, YouTubeIcon];
  return iconsConfig[source - 1] || CheckCircleOutlineOutlinedIcon;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const customArraySort = (arr: any = [], indexName: any) => {
  const indexToMove = arr.findIndex((obj: any) => obj.name.toLowerCase() === indexName);

  let movedObj = null;
  if (indexToMove !== -1) {
    movedObj = arr.splice(indexToMove, 1)[0];
  }

  arr.sort((a: any, b: any) => a.name - b.name);

  if (movedObj) {
    arr.unshift(movedObj);
  }

  return arr;
};

export const sortArrByKey = <T>(arr: T[] = [], keySort: string, dir: 'asc' | 'desc' = 'asc') => {
  return [...arr].sort((a: any, b: any) => {
    if (dir === 'asc') {
      return a[keySort] - b[keySort];
    }

    return b[keySort] - a[keySort];
  });
};

export const updateOrderByIndexes = <T>(arr: T[]) => {
  return arr.map((item, index) => ({ ...item, order: index + 1 }));
};

export const convertNumbersToK = (number: number | string): string => {
  if (!number || number === 0 || !Number(number)) {
    return '0';
  }

  if (number > 1000) {
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixIndex = Math.floor(Math.log10(Number(number)) / 3);
    const abbreviatedNumber = (Number(number) / Math.pow(1000, suffixIndex)).toFixed(1);
    return abbreviatedNumber + suffixes[suffixIndex];
  }
  return number.toString();
};

export const numberWithCommas = (x: number | string) => {
  const val = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return val || x;
};

export const catchRequestErrors = (
  error: any,
  setErrorFn: UseFormSetError<any>,
  setGlobalError: Dispatch<SetStateAction<string>>,
  setLoading: (arg: boolean) => void
) => {
  const errorResponse = error?.response?.data?.error;
  if (
    Array.isArray(errorResponse?.data) ||
    Array.isArray(error?.response?.data?.error?.data) ||
    Array.isArray(errorResponse?.data)
  ) {
    errorResponse.data.forEach((err: any) => {
      setErrorFn(err.field, {
        type: 'manual',
        message: err.error
      });
    });
  }
  if (typeof errorResponse?.data === 'string') {
    setGlobalError(errorResponse?.data);
  }
  setLoading(false);
};

export const getPercentage = (
  value: number,
  totalData: number,
  withSymbol = true,
  fractionDigits = 1
): string | number => {
  const percentage = (value * 100) / totalData || 0;
  const resultValue = parseFloat(percentage.toFixed(fractionDigits));

  if (withSymbol) {
    return `${resultValue}%`;
  }

  return resultValue;
};

type AnyVoidFunction = (...arg: Array<any>) => void;

export const debounce = <T extends AnyVoidFunction>(func: T, wait: number): (() => void) => {
  let timeout: any;
  return function executedFunction(...args: Parameters<AnyVoidFunction>) {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
};

export const getTimeFrame = (fromDate: string, toDate: string, zone?: string) => {
  if (!zone) {
    return `${DateTime.fromISO(fromDate).toUTC().toFormat('LLL dd, yyyy')} - ${DateTime.fromISO(toDate)
      .toUTC()
      .toFormat('LLL dd, yyyy')}`;
  }

  return `${DateTime.fromISO(fromDate).setZone(zone).toFormat('LLL dd, yyyy')} - ${DateTime.fromISO(toDate)
    .setZone(zone)
    .toFormat('LLL dd, yyyy')}`;
};
