import { API_ROUTES } from 'const';
import { useAppSelector } from 'hooks';
import { DateTime } from 'luxon';
import { IConversationSummaryItem, ICreateSummary } from 'modules/Charts/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getUser } from 'store/slices/authSlice';
import useSWR from 'swr';
import { Datasource, FiltersDateRangeType, Sentiment } from 'types';
import { fetchWithConfig } from 'utils';

interface IUseSummariesProps {
  queryFilters: {
    from: Date | string;
    to: Date | string;
    filtersRangeType: FiltersDateRangeType;
    stockSymbol: string;
    dataSource: Datasource[];
    sentiments?: Sentiment[];
  };
  interval: string;
  categoryDates: string[];
  seriesClickConfig: Record<number, any>;
}

const useSummaries = (props: IUseSummariesProps) => {
  const { from: filterFrom, to: filterTo, stockSymbol, dataSource, filtersRangeType, sentiments } = props.queryFilters;
  const { interval, categoryDates, seriesClickConfig } = props;

  const user = useAppSelector(getUser);
  const [summaryActiveData, setSummaryActiveData] = useState<IConversationSummaryItem | null>(null);
  const [summaryPrevData, setSummaryPrevData] = useState<IConversationSummaryItem | null>(null);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [summaryPopupData, setSummaryPopupData] = useState<{ show: boolean; data: ICreateSummary | null }>({
    show: false,
    data: null
  });
  const { current: sseRequestId } = useRef(Date.now().toString(36) + Math.random().toString(36).substring(2));

  const clearSummaryData = useCallback(() => {
    setSummaryActiveData(null);
    setSummaryPrevData(null);
  }, []);

  useEffect(() => {
    clearSummaryData();
  }, [filterTo, filterFrom, stockSymbol, filtersRangeType]);

  const {
    data: conversationSummaries,
    isLoading: summariesLoading,
    mutate: mutateSummaries
  } = useSWR<IConversationSummaryItem[]>(
    stockSymbol
      ? [`${API_ROUTES.CONVERSATION_SUMMARIES}/${stockSymbol}`, { from: filterFrom, to: filterTo, sentiments }]
      : null,
    (options: [string, Record<string, any>]) => {
      return fetchWithConfig<IConversationSummaryItem[]>({
        url: options[0],
        params: options[1]
      });
    }
  );

  const summaries = conversationSummaries || [];

  useEffect(() => {
    if (!user._id) {
      return;
    }

    const eventSource = new EventSource(
      `${process.env.REACT_APP_API_BASE_URL}/conversation-summary-events/conversation-generated/${sseRequestId}`
    );
    eventSource.onmessage = ({ data }) => {
      mutateSummaries();

      setSummaryActiveData(JSON.parse(data).data.conversationSummary);
      setTimeout(() => {
        setIsGeneratingSummary(false);
      }, 1000);
    };

    return () => {
      eventSource.close();
    };
  }, [sseRequestId, user._id]);

  const onCloseSummaryPopup = () => {
    setSummaryPopupData({ show: false, data: null });
  };

  const onMarkerClick = async (e: any, opts: any, pointData: { seriesIndex: number; dataPointIndex: number }) => {
    if (interval !== '1d') {
      return;
    }

    const { dataPointIndex, seriesIndex } = pointData;
    const onChatterMarkerClick = Object.keys(seriesClickConfig)
      .map((val) => +val)
      .includes(seriesIndex);

    if (!onChatterMarkerClick || isGeneratingSummary) {
      return;
    }

    let sentiment: Sentiment | null = null;

    if (sentiments) {
      sentiment = seriesClickConfig[seriesIndex];
    }

    const date = categoryDates[dataPointIndex]!;
    const summary = summaries.find(
      ({ dayDate, sentiment: summarySentiment }) =>
        dayDate === date && (!sentiment ? true : summarySentiment === sentiment)
    );

    if (summary) {
      setSummaryPrevData(summaryActiveData);
      setSummaryActiveData(summary);
      setTimeout(() => {
        setSummaryPrevData(summary);
      }, 1000);
      return;
    }

    setSummaryPopupData({
      show: true,
      data: {
        stock: stockSymbol,
        sentiment,
        date: DateTime.fromJSDate(new Date(date)).toISODate()!,
        dataSource: [...dataSource].sort((a: number, b: number) => a - b),
        sseRequestId
      }
    });
  };

  const onGenerateSummary = async (data: ICreateSummary) => {
    const { stock, date, dataSource, sseRequestId, sentiment } = data;
    setIsGeneratingSummary(true);
    setSummaryActiveData(null);
    setSummaryPrevData(null);

    await fetchWithConfig({
      url: `${API_ROUTES.CONVERSATION_SUMMARIES}/${stockSymbol}`,
      method: 'post',
      data: {
        stock,
        date: DateTime.fromJSDate(new Date(date)).toISODate(),
        dataSource: [...dataSource].sort((a: number, b: number) => a - b),
        sseRequestId,
        ...(sentiment && { sentiment })
      }
    });
  };

  return {
    onMarkerClick,
    onCloseSummaryPopup,
    summariesLoading,
    onGenerateSummary,
    summaries,
    summaryPrevData,
    summaryActiveData,
    summaryPopupData,
    isGeneratingSummary,
    clearSummaryData
  };
};

export default useSummaries;
