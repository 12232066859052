import { ArrowDownward, ArrowUpward, Notifications } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import classNames from 'classnames';
import { SectionCard } from 'components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { numberWithCommas } from 'utils';

import { ISummaryResponse, SummaryItem } from '../types';
import styles from './styles.module.scss';

interface ICardProps {
  dataKey: keyof ISummaryResponse;
  data: SummaryItem[];
}

const ChangeValue = (props: { value: SummaryItem['value']; dataKey: ICardProps['dataKey'] }) => {
  const { value = '', dataKey } = props;

  const { valueString, Icon } = useMemo(() => {
    if (dataKey === 'priceUp' || dataKey === 'priceDown') {
      return {
        Icon:
          dataKey === 'priceUp' ? (
            <ArrowUpward className={styles.positive} />
          ) : (
            <ArrowDownward className={styles.negative} />
          ),
        valueString: `${+value > 0 ? '+' : ''}${value}%`
      };
    }

    if (dataKey === 'alerts') {
      return {
        Icon: <Notifications className={styles.warning} fontSize="small" />,
        valueString: `${numberWithCommas(value)} new`
      };
    }

    if (dataKey === 'mentions') {
      return {
        Icon: <ArrowUpward className={styles.positive} />,
        valueString: `+${value}%`
      };
    }

    if (dataKey === 'positive') {
      return {
        Icon: <ArrowUpward className={styles.positiveLight} />,
        valueString: `+${value}%`
      };
    }

    if (dataKey === 'negative') {
      return {
        Icon: <ArrowUpward className={styles.negativeLight} />,
        valueString: `+${value}%`
      };
    }

    return { valueString: '', Icon: null };
  }, [dataKey, value]);

  return (
    <div className={classNames(styles.changeValue, styles[dataKey])}>
      {Icon}
      <span className={styles.value}>{valueString}</span>
    </div>
  );
};

const Card = (props: ICardProps) => {
  const { dataKey, data } = props;
  const { t } = useTranslation();
  const onSymbolClick = (symbol: string) => {
    const row = document.getElementById(`table-row-${symbol}`);
    if (row) {
      const lastTd = row.querySelector('td:last-child');
      if (lastTd) {
        const button = lastTd.querySelector('button');
        if (button) {
          button.click();
        }
      }
      // a small delay to make sure the row is rendered
      setTimeout(() => {
        row.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  };

  return (
    <SectionCard nativeClassName={styles.card}>
      <>
        <h3 className={styles.title}>{t(`agencySummary.dataTypes.${dataKey}`)}</h3>
        <div className={styles.stocksList}>
          {data.length === 0 && <span className={styles.noData}>{t(`agencySummary.dataTypes.no${dataKey}`)}</span>}
          {data.map((item) => {
            const { _id, symbol, image, value } = item;
            return (
              <div className={styles.stockData} key={_id}>
                <div className={styles.stockInfo}>
                  <Avatar sx={{ width: 32, height: 32 }} src={image} />
                  <span className={styles.stockSymbol} onClick={() => onSymbolClick(symbol)}>
                    {symbol}
                  </span>
                </div>
                <ChangeValue dataKey={dataKey} value={value} />
              </div>
            );
          })}
        </div>
      </>
    </SectionCard>
  );
};

export default Card;
