import classNames from 'classnames';
import { CrudActions, CrudTable, Title, TitleType } from 'components';
import { API_ROUTES } from 'const';
import { useAppSelector } from 'hooks';
import { TabFilters } from 'modules';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserStockSymbol } from 'store/slices/authSlice';
import { useSWRConfig } from 'swr';
import { IReport, IReportConfig, ITableColumns, TabConfig } from 'types';
import { getTimeFrame } from 'utils';

import ReportActionsMenu from './ReportActionsMenu';
import ReportArchivedActionsMenu from './ReportArchivedActionsMenu';
import ReportStatus from './ReportStatus';
import styles from './styles.module.scss';

const reportRows: ITableColumns<IReport>[] = [
  {
    title: 'Report Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Report Type',
    dataIndex: 'type',
    key: 'type',
    render: (item, value, refresh, t) => {
      return t(`pages.reports.reportTypes.${value}`);
    }
  },
  {
    title: 'Stock Name',
    dataIndex: 'stock',
    key: 'stock',
    render: (item, value) => {
      return <span className={styles.stock}>{value}</span>;
    }
  },
  {
    title: 'Time Frame',
    dataIndex: '',
    key: '',
    render: (item, value) => {
      if (item.fromDate && item.toDate) {
        return <span className={styles.timeFrame}>{getTimeFrame(item.fromDate, item.toDate)}</span>;
      }

      if (item.firstPeriod && item.secondPeriod) {
        return (
          <div className={styles.timeFrames}>
            <span className={styles.timeFrame}>{getTimeFrame(item.firstPeriod[0], item.firstPeriod[1])}</span>
            <span className={styles.timeFrame}>{getTimeFrame(item.secondPeriod[0], item.secondPeriod[1])}</span>
          </div>
        );
      }

      return '-';
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (item) => {
      return <ReportStatus report={item} />;
    }
  },
  {
    title: '',
    dataIndex: '_id',
    key: '_id',
    render: (item, value, refresh) => {
      return <ReportActionsMenu report={item} refresh={refresh} />;
    }
  }
];

const archivedRows: ITableColumns<IReport>[] = [
  {
    title: 'Report Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Stock Name',
    dataIndex: 'stock',
    key: 'stock',
    render: (item, value) => {
      return <span className={styles.stock}>{value}</span>;
    }
  },
  {
    title: 'Time Frame',
    dataIndex: '',
    key: '',
    render: (item, value) => {
      if (item.fromDate && item.toDate) {
        return <span className={styles.timeFrame}>{getTimeFrame(item.fromDate, item.toDate)}</span>;
      }

      if (item.firstPeriod && item.secondPeriod) {
        return (
          <div className={styles.timeFrames}>
            <span className={styles.timeFrame}>{getTimeFrame(item.firstPeriod[0], item.firstPeriod[1])}</span>
            <span className={styles.timeFrame}>{getTimeFrame(item.secondPeriod[0], item.secondPeriod[1])}</span>
          </div>
        );
      }

      return '-';
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (item) => {
      return <ReportStatus report={item} />;
    }
  },
  {
    title: '',
    dataIndex: '_id',
    key: '_id',
    render: (item, value, refresh) => {
      return <ReportArchivedActionsMenu report={item} refresh={refresh} />;
    }
  }
];

const reportConfigRows: ITableColumns<IReportConfig>[] = [
  {
    title: 'Report Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Stock Name',
    dataIndex: 'stock',
    key: 'stock',
    render: (item, value) => {
      return <span className={styles.stock}>{value}</span>;
    }
  },
  {
    title: 'Schedule type',
    dataIndex: 'scheduleType',
    key: 'scheduleType',
    render: (item, value) => {
      return <span className={classNames(styles.status, styles[value])}>{value}</span>;
    }
  },
  {
    title: '',
    dataIndex: '_id',
    key: '_id',
    render: (item, value, refresh) => {
      return (
        <>
          <CrudActions
            editReplace={true}
            toEdit={`${window.location.href}?id=${value}`}
            onRemoveUrl={`${API_ROUTES.REPORT_CONFIGS}/${value}`}
            dialogTitle={
              <div>
                <Title type={TitleType.h3}>
                  <>Confirm deletion of Report Configuration.</>
                </Title>
              </div>
            }
            refresh={refresh}
          />
        </>
      );
    }
  }
];

interface IReportsListProps {
  teamId: string | undefined;
}

const ReportsList: React.FC<IReportsListProps> = ({ teamId }) => {
  const { mutate } = useSWRConfig();
  const [activeTab, setActiveTab] = useState('reports');
  const selectedStock = useAppSelector(getUserStockSymbol);

  const { t } = useTranslation();

  useEffect(() => {
    if (!teamId) {
      return;
    }

    const eventSource = new EventSource(
      `${process.env.REACT_APP_API_BASE_URL}/reports-events/report-generated/${teamId}`
    );
    eventSource.onmessage = ({ data }) => {
      mutate([API_ROUTES.REPORTS, { page: 1, take: 10, stock: selectedStock }]);
    };

    return () => {
      eventSource.close();
    };
  }, [teamId, selectedStock]);

  const tabConfig: TabConfig[] = useMemo(() => {
    return [
      {
        name: t('pages.reports.tabs.reports'),
        isActive: activeTab === 'reports',
        toggleFn: () => {
          setActiveTab('reports');
        }
      },
      {
        name: t('pages.reports.tabs.scheduled'),
        isActive: activeTab === 'scheduled',
        toggleFn: () => {
          setActiveTab('scheduled');
        }
      },
      {
        name: t('pages.reports.tabs.archived'),
        isActive: activeTab === 'archived',
        toggleFn: () => {
          setActiveTab('archived');
        }
      }
    ];
  }, [activeTab, t]);

  return (
    <>
      <TabFilters data={tabConfig} />
      <CrudTable<IReport>
        noBorder
        queryOptions={{ stock: selectedStock, ...(activeTab === 'archived' && { archived: true }) }}
        columns={activeTab === 'reports' ? reportRows : activeTab === 'scheduled' ? reportConfigRows : archivedRows}
        requestUrl={
          activeTab === 'reports' || activeTab === 'archived' ? API_ROUTES.REPORTS : API_ROUTES.REPORT_CONFIGS
        }
      />
    </>
  );
};

export default ReportsList;
