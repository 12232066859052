import React from 'react';

import ComparisonForm from './Comparison';
import PeriodicForm from './Periodic';

interface IInfoFormProps {
  control: any;
  register: any;
  errors: any;
  setValue: any;
  watch: any;
  setError: any;
  configId?: string | null;
}

const InfoForm: React.FC<IInfoFormProps> = ({ control, register, errors, setValue, watch, setError, configId }) => {
  const type = watch('type');

  if (type === 'comparison') {
    return (
      <ComparisonForm
        control={control}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        setError={setError}
      />
    );
  }

  return (
    <PeriodicForm
      configId={configId}
      control={control}
      register={register}
      errors={errors}
      setValue={setValue}
      watch={watch}
      setError={setError}
    />
  );
};

export default InfoForm;
