import GaugeChart from 'components/SentimentGauge/GaugeChart';
import { IHardDataExecutiveSummary } from 'modules/ExecutiveSummary';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { ExecutiveSummaryDaysFilter } from 'types';

import CardSkeleton from '../../../components/SentimentalsBlocks/CardSkeleton';
import { AlertIcon, Barchart, TrendIcon, UserIconTrend } from '../../../components/SvgComponents';
import { API_ROUTES } from '../../../const';
import { useAppSelector } from '../../../hooks';
import { getUserStockSymbol } from '../../../store/slices/authSlice';
import { fetchWithConfig } from '../../../utils';
import Card from '../Card';
import { ExecutiveSummaryResponse, SummaryType } from '../types';
import styles from './styles.module.scss';

interface ISummaryListProps {
  symbol?: string;
  filterDay: ExecutiveSummaryDaysFilter;
  hardData?: IHardDataExecutiveSummary;
}

const SummaryList = (props: ISummaryListProps) => {
  const { t } = useTranslation();
  const { symbol, filterDay, hardData } = props;
  const stockSymbol = useAppSelector(getUserStockSymbol);

  const stockSymbolToGet = symbol || stockSymbol;

  const { data, error, isLoading } = useSWR<ExecutiveSummaryResponse>(
    stockSymbolToGet ? `${API_ROUTES.EXECUTIVE_SUMMARY}/${stockSymbolToGet}?filterDayType=${filterDay}` : null,
    (url) => {
      return fetchWithConfig({
        url
      });
    }
  );

  const finalData: ExecutiveSummaryResponse | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return {
      mentions: {
        ...data.mentions,
        value: hardData?.mentions || data.mentions.value
      },
      sentiment: {
        positive: {
          ...data.sentiment.positive,
          value: hardData?.sentiment.positive || data.sentiment.positive.value
        },
        negative: {
          ...data.sentiment.negative,
          value: hardData?.sentiment.negative || data.sentiment.negative.value
        },
        neutral: {
          ...data.sentiment.neutral,
          value: hardData?.sentiment.neutral || data.sentiment.neutral.value
        }
      },
      bots: data.bots,
      alerts: data.alerts
    };
  }, [hardData, data]);

  return (
    <div className={styles.wrapper}>
      {!stockSymbolToGet || isLoading ? (
        <>
          {Array(4)
            .fill(1)
            .map((item, index) => (
              <CardSkeleton key={index} />
            ))}
        </>
      ) : finalData ? (
        <>
          <Card
            loading={isLoading}
            title={t('executiveSummary.mentions')}
            Icon={Barchart}
            data={finalData.mentions}
            type={SummaryType.simple}
            filterDay={filterDay}
          />
          <Card
            loading={isLoading}
            title={t('executiveSummary.sentiment')}
            Icon={TrendIcon}
            data={finalData.sentiment}
            type={SummaryType.chart}
            filterDay={filterDay}
          />
          <Card
            cardKey="bots"
            loading={isLoading}
            title={t('executiveSummary.bots')}
            Icon={UserIconTrend}
            data={finalData.bots}
            type={SummaryType.simple}
            filterDay={filterDay}
            ExtraComponent={
              <div className={styles.gaugeWrapper}>
                <GaugeChart loading={false} percentage={parseFloat(finalData.bots.value as string)} />
              </div>
            }
          />
          <Card
            loading={isLoading}
            title={t('executiveSummary.alerts')}
            Icon={AlertIcon}
            data={finalData.alerts}
            type={SummaryType.simple}
            filterDay={filterDay}
          />
        </>
      ) : null}
    </div>
  );
};

export default SummaryList;
