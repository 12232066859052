import { ArrowUpwardRounded } from '@mui/icons-material';

import styles from './styles.module.scss';

export const getNegativeSentimentIncreaseArrow = () => {
  return (
    <div className={styles.arrowCircle}>
      <ArrowUpwardRounded className={styles.redArrow} />
    </div>
  );
};

export const getChatterVolumeArrow = () => {
  return (
    <div className={styles.arrowCircle}>
      <ArrowUpwardRounded className={styles.grayArrow} />
    </div>
  );
};
